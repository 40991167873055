<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span>
        </div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Корректировка бюджета</div>
        <b-tabs class="w-100">
            <b-tab title="Расходы" active lazy>
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(5)">Режим «Корректировка бюджета»<i
                                    class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Режим предназначен для корректировки и внесения изменений в утвержденный/уточненный
                                    бюджет.</p>
                                <p>В случае, если после произведенной корректировки проводится уточнение бюджета,
                                    скорректированные показатели бюджета отражаются в уточненном бюджете</p>
                                <p>Режим содержит данные до уровня бюджетных программ/подпрограмм по расходной части и
                                    виды показателей корректировок бюджета.</p>
                                <p>Данный режим состоит из разделов:</p>
                                <p><span class="red bold">Расходы</span><br> - корректировка расходной части бюджета</p>
                                <p><span class="red bold">Доходы</span><br> - корректировка доходной части бюджета</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-download"></i> Скачать
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadToExcelAll">Отчет по корректировкам обл бюджета
                        </b-dropdown-item>
                        <b-dropdown-item @click="downloadToExcel">Отчет по корректировке обл бюджета</b-dropdown-item>
                    </b-dropdown>
                </div>
                <template>
                    <cost-correct :year="year"
                                  :obl="obl"
                                  :reg="region"
                                  :variant="variant"
                                  :list-reg="listReg"
                                  :list-variants="listVariants"
                                  @change_filter="changeChildFilter"
                    ></cost-correct>
                </template>
            </b-tab>
            <b-tab title="Доходы" lazy>
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(6)">Режим «Корректировка бюджета»<i
                                    class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Режим предназначен для корректировки и внесения изменений в утвержденный/уточненный
                                    бюджет.</p>
                                <p>В случае, если после произведенной корректировки проводится уточнение бюджета,
                                    скорректированные показатели бюджета отражаются в уточненном бюджете.</p>
                                <p>Режим содержит данные до уровня бюджетных программ/подпрограмм по расходной части и
                                    виды показателей корректировок бюджета.</p>
                                <p>Данный режим состоит из разделов:</p>
                                <p><span class="red bold">Расход</span><br> - корректировка расходной части бюджета</p>
                                <p><span class="red bold">Доход</span><br> - корректировка доходной части бюджета.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-download"></i> Скачать
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadToExcelAll">Отчет по корректировкам обл бюджета
                        </b-dropdown-item>
                        <b-dropdown-item @click="downloadToExcelDoh">Отчет по корректировке обл бюджета(доход)
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <template>
                    <income-correct :year="year"
                                    :obl="obl"
                                    :reg="region"
                                    :variant="variant"
                                    :list-reg="listReg"
                                    :list-variants="listVariants"
                                    @change_filter="changeChildFilter"
                    ></income-correct>
                </template>
            </b-tab>
            <b-tab v-if="is_available('004.003.005.000')" title="Версия бюджета">
                <budget-variants></budget-variants>
            </b-tab>
            <b-tab v-if="false" title="Test">
                <income-correct-table></income-correct-table>
            </b-tab>
<!--            <b-tab title="Специфики">-->
<!--                <cost-correct-spf :year="year"-->
<!--                    :obl="obl"-->
<!--                    :reg="region"-->
<!--                    :variant="variant"-->
<!--                    :list-reg="listReg"-->
<!--                    :list-variants="listVariants"-->
<!--                    @change_filter="changeChildFilter"-->
<!--                ></cost-correct-spf>-->
<!--            </b-tab>-->
            <!-- <b-tab title="Баланс">
            </b-tab> -->
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CostCorrect from '@/modules/budget/CostCorrect';
import CostCorrectSpf from '@/modules/budget/budget-correct/CostCorrectSpf.vue';
import IncomeCorrect from '@/modules/budget/IncomeCorrect';
import IncomeCorrectTable from '@/modules/budget/IncomeCorrectTable';
import {Ax} from '@/utils';
import store from '../../services/store';
import {getActualVariant, loadVariantsByRegionCode, loadVariantsByUserId} from '@/modules/budget/budgetVariantService';
import {CUR_YEAR} from '@/modules/budget-request/components/js/budgetCurYear';
import BudgetVariants from '@/modules/budget/BudgetVariants';
import App from '../../App.vue'

export default {
    name: 'BudgetCorrect',
    components: {CostCorrect, IncomeCorrect, BudgetVariants, IncomeCorrectTable},
    data() {
        return {
            year: CUR_YEAR + 1,
            obl: null,
            region: null,
            variant: null,
            selectedABP: {id: 0, child: []},
            selectedPRG: {id: 0, child: []},
            selectedPPR: {id: 0},
            listReg: [],
            listVariants: []
        };
    },

    async mounted() {
        this.year = CUR_YEAR + 1;
        await this.getObl();
        await this.loadRegions();
    },

    methods: {
        async changeChildFilter(filter) {
            this.variant = filter.variant;
            this.year = filter.year;
            this.region = filter.region;
            await this.loadVariantsList();
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        // this.region = json.region;
                    });
                await getActualVariant(this.region).then(data => {
                    this.variant = data;
                });
                await this.loadVariantsList();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        is_available(module) {
            const find_mod = store.getters.user.userModules.find(value => value.modules == module)
            return find_mod
        },

        async loadVariantsList() {
            try {
                await loadVariantsByRegionCode(this.region).then(data => {
                    this.listVariants = data;
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadVariantsList', error.toString());
            }
        },

        async loadRegions() { // справочник регионов
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.$store.getters.user_uuid);
                this.listReg = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        },
        changeParams(data) {
            this.selectedABP = data.selABP;
            this.selectedPRG = data.selPRG;
            this.selectedPPR = data.selPPR;
        },
        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id == 5) {
                        file = 'РП Корректировка расходной части бюджета';
                    }
                    if (info_id == 6) {
                        file = 'РП Корректировка доходной части бюджета';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadToExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/correct-download/1/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_по_корректировке_обл_бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadToExcelDoh() {
            const that = this;
            Ax(
                {
                    url: '/api-py/correct-download/2/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_по_корректировке_обл_бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        downloadToExcelAll() {
            const that = this;
            Ax(
                {
                    url: '/api-py/correct-download/3/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_по_корректировке_обл_бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение
    }
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>