




















import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Component, Vue, Prop} from 'vue-property-decorator';

import VueElementLoading from "vue-element-loading";
@Component({
    components: {

        'loading': VueElementLoading
    }
})
export default class IncomeCorrectTable extends Vue {
    private data: any[] = [];
    private showData: any = {};
    private repYear = 2022;
    private region = '450101';
    private variant = '448f3dfb-bfba-4a79-97c6-52b61b6a6e63'
    async getNewItemBudgetDataNoPpr() {
        try {
            const response = await fetch('/api-py/get-cost-correct-by-yrv/'
                + this.repYear + '/' + this.region + '/' + this.variant);
            return await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getFields', error.toString());
        }
    }
    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true
        });
    }
    private async mounted () {
        this.prepareData()
    }
    private padLeadingZeros(num:number, size: number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    private async prepareData () {
        const loadedData = await this.getNewItemBudgetDataNoPpr();
        const groupFields = ['abp', 'prg', 'ppr', 'field'];
        const sumFields = ['value', 'abp_request', 'approved_budget'];
        const sourceFields = ['note', 'is_correct_counted'];
        loadedData.forEach((row: any) => {
            row['key_value'] ='';
            groupFields.forEach((fld: string) => {
                row['key_value'] = row['key_value'].toString() + this.padLeadingZeros(row[fld], 3);
            })
        })


        const t = loadedData.reduce((group: any, row: any) => {
            const {key_value} = row;
            const finish_row: any = {}
            groupFields.forEach((fld: string) => {
                finish_row[fld] = row[fld];
            })
            sumFields.forEach((fld: string) => {
                finish_row[fld] = row[fld];
            })
            finish_row['key_value'] = row['key_value'];
            finish_row['child'] = finish_row['child'] ?? []
            group[key_value] =  group[key_value] ?? {};
            let key_v: string = '';
            groupFields.forEach((fld: string) => {
                key_v = key_v + this.padLeadingZeros(row[fld], 3);
                group[key_v] = group[key_v] ?? {}
                const key_lenght = key_v.toString().trim().length/3
                for (let i=0; i<groupFields.length; i++) {
                    const gr_fld = groupFields[i];
                    if (i<key_lenght) {
                        group[key_v][gr_fld] = group[key_v][gr_fld] ?? row[gr_fld];
                    } else {
                        group[key_v][gr_fld] = group[key_v][gr_fld] ?? null;
                    }
                }
                sumFields.forEach((sum_fld: string) => {
                    group[key_v][sum_fld] = group[key_v][sum_fld] ?? 0;
                    group[key_v][sum_fld] = group[key_v][sum_fld] + row[sum_fld];
                    group[key_v]['child'] = group[key_v]['child'] ?? [];
                    // group[key_v]['child'] = group[key_v]['child'].push(null);
                })
            })
            // group[key_value]['child'] = group[key_value]['child'] ?? [];
            // group[key_value]['child'].push(finish_row);
            return group;
        }, {})
        this.showData = t;
        this.data = loadedData;
    }
}
